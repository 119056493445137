import styled from 'styled-components'

import { Title } from '../../../common/styles'

export const ContentContainer = styled.div`
  padding: 21px;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const MainContent = styled.div`
  flex: 1;
  overflow: auto;
`

export const PrestionCardButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 21px;
`

export const TitleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7px;
  padding-bottom: 14px;
`

export const CardTitle = styled(Title)`
  padding-bottom: 0;
  flex: 1;
`
