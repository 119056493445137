import { PageProps } from 'gatsby'
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import styled from 'styled-components'

import { Accent, Intro, TitleLarge } from '../../common/styles'
import MarkdownWrapper from '../../components/MarkdownWrapper'
import Layout from '../../layouts'

import PrestationCard from './PrestationCard'
import { Container, PrestationsContainer } from './PrestationSection.styles'

const Title = styled(TitleLarge)`
  margin-top: 28px;
  padding-right: 7px;
  padding-left: 7px;
`

const PrestationsSection = ({
  data: {
    allStrapiService: { edges },
    strapiServicesPage
  }
}: Pick<PageProps<Queries.ServicesPageQuery>, 'data'>) => (
  <Layout
    smallNavBar
    seo={{
      title: strapiServicesPage?.seo?.title,
      description: strapiServicesPage?.seo?.description
    }}
  >
    <Container>
      <Title>
        Prestations de <Accent>maquillage</Accent>
      </Title>
      <Intro>
        {strapiServicesPage?.intro && (
          <MarkdownWrapper>
            {strapiServicesPage?.intro?.data?.intro!}
          </MarkdownWrapper>
        )}
      </Intro>
      <PrestationsContainer>
        {edges?.length > 0 &&
          [...edges]
            .sort((a, b) => Number(a.node.index) - Number(b.node.index))
            .map(
              ({
                node: { id, illustration, shortDescription, title, path }
              }) => {
                const imageData =
                  illustration?.localFile as unknown as IGatsbyImageData
                const image = getImage(imageData!)
                return (
                  <PrestationCard
                    key={id}
                    image={image}
                    shortDescription={shortDescription?.data?.shortDescription!}
                    title={title}
                    path={path}
                  />
                )
              }
            )}
      </PrestationsContainer>
    </Container>
  </Layout>
)

export default PrestationsSection
