import { IGatsbyImageData } from 'gatsby-plugin-image'
import { FC } from 'react'

import { ButtonLink } from '../../../common/styles'
import ResponsiveCard from '../../../components/Chip/ResponsiveCard'
import MarkdownWrapper from '../../../components/MarkdownWrapper'
import colors from '../../../constants/colors'

import {
  MainContent,
  PrestionCardButtonContainer
} from './PrestationCard.styles'

type Props = {
  title: string | null
  shortDescription: string | null
  image: IGatsbyImageData | undefined
  path: string | null
}

const PrestationCard: FC<Props> = ({
  title,
  image,
  shortDescription,
  path
}) => (
  <ResponsiveCard
    imageData={image!}
    imageAlt={`Image d'illustration pour la prestation: ${title}`}
    title={title!}
    content={
      <>
        <MainContent>
          <MarkdownWrapper>{shortDescription!}</MarkdownWrapper>
        </MainContent>
        <PrestionCardButtonContainer>
          <ButtonLink to={`/prestations/${path}/`} color={colors.pink} large>
            En savoir plus
          </ButtonLink>
        </PrestionCardButtonContainer>
      </>
    }
  />
)

export default PrestationCard
