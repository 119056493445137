import { graphql, PageProps } from 'gatsby'

import PrestationsSection from '../sections/PrestationsSection'

const PrestationsHome = ({ data }: PageProps<Queries.ServicesPageQuery>) => {
  return <PrestationsSection data={data} />
}

export default PrestationsHome

export const query = graphql`
  query ServicesPage {
    allStrapiService {
      edges {
        node {
          index
          id
          illustration {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 300, formats: [AUTO, WEBP])
              }
            }
          }
          shortDescription {
            data {
              shortDescription
            }
          }
          title
          path
        }
      }
    }
    strapiServicesPage {
      intro {
        data {
          intro
        }
      }
      seo {
        description
        title
      }
    }
  }
`
